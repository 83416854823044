<template>
  <div id="couple-detail">
    <global-nav-header :is-logged-in="isLoggedIn"/>

    <div class="content-wrapper">
      <h1>{{ couple.sequence }}번째 커플, {{ couple.married_year }}년</h1>

      <a :href="couple.image_url" target="_blank">
        <img :src="couple.image_url" alt="Couple Photo" class="couple-photo-detail">
      </a>

      <p v-if="couple.developer_comment" class="description">{{ couple.developer_comment }}</p>

      <a href="/couples" class="back-link">← 명예의 전당 목록으로 돌아가기</a>
    </div>
  </div>
</template>

<script>
import GlobalNavHeader from "./global_nav_header.vue";

export default {
  components: {GlobalNavHeader},
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    couple: {
      type: Object,
      default: {}
    }
  },
  mounted() {
    console.log('커플 상세 페이지가 마운트되었습니다.');
  },
}
</script>

<style scoped lang="scss">
@import "../../stylesheets/common";

#couple-detail {
  .content-wrapper {
    max-width: $desktop-max-width;
    margin: 20px auto;
    padding: 20px;
    text-align: center;

    h1 {
      font-size: 30px;
      margin-bottom: 20px;
    }

    .couple-photo-detail {
      width: 100%;
      max-width: 600px;
      height: auto;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      margin-bottom: 20px;
    }

    .description {
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 30px;
    }

    .back-link {
      display: inline-block;
      margin-top: 20px;
      font-size: 16px;
      color: #007bff;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: #0056b3;
      }
    }
  }
}
</style>
