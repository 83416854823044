<template>
  <div id="couples">
    <global-nav-header :is-logged-in="isLoggedIn"/>
    <div class="content-wrapper">
      <h1>커피한잔 명예의 전당</h1>

      <p class="subtitle">
        결혼 커플들에게 받은 연락을 모아봤어요.<br>
        오랜 기간 서비스를 만들어 오며 생긴 추억이자 자랑이죠.<br>
        커피한잔을 통해 탄생한 가족들이 모두 행복하고 건강하길 바래요.
      </p>

      <div class="couples-grid">
        <a class="couple-item" v-for="(couple, index) in couples" :key="index" :href="`/couples/${couple.id}`">
          <img :src="couple.image_url" alt="Couple Photo" class="couple-photo">
          <p>{{ couple.sequence }}번째 커플, {{ couple.married_year }}년</p>
        </a>
      </div>

      <p>
        * 결혼한 분들에게 연락이 온 순서입니다.<br>
        * 누락이 되었다면 <a href="mailto:withcoffee.me@gmail.com">withcoffee.me@gmail.com</a>으로 알려주세요.<br>
      </p>
    </div>
  </div>
</template>

<script>
import GlobalNavHeader from "./global_nav_header.vue";

export default {
  components: {GlobalNavHeader},
  methods: {
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    couples: {
      type: Array,
      default: []
    }
  },
  mounted() {
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../stylesheets/common";

#couples {
  .content-wrapper {
    padding: 0;
    max-width: $desktop-max-width;
    margin: 20px auto;
    text-align: left;
    word-break: keep-all;
    font-size: 16px;

    .subtitle {
      font-size: 16px;
      text-align: center;
      margin: 20px;
    }

    h1 {
      font-size: 25px;
      text-align: center;
      margin: 25px;
    }

    h2 {
      font-size: 22px;
      text-align: center;
      margin: 25px;
    }

    .couples-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* 기본: 3열 */
      gap: 10px;

      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr); /* 모바일: 2열 */
      }

      .couple-item {
        text-align: center;
        text-decoration: none;
        color: inherit;
        cursor: pointer;

        .couple-photo {
          width: 220px;
          height: 123px;
          object-fit: cover;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

          @media (max-width: 480px) {
            width: 182px;
            height: 120px;
          }
        }

        p {
          margin: 6px 0;
          font-size: 14px;
        }
      }
    }

    p {
      margin: 12px;
      font-size: 13px;
    }
  }
}
</style>