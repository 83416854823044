<template>
  <nav class="navbar navbar-expand-lg navbar-dark sticky-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="/home">
        <img :src="logoSrc" width="30" height="30" alt="커피한잔 로고" loading="lazy">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav w-100">
          <li class="nav-item">
            <a class="nav-link"
               v-bind:class="{active: active === '/companies/major'}"
               href="/companies/major">커피한잔 소개</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               v-bind:class="{active: active === '/developer'}"
               href="/developer">개발자 소개</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               v-bind:class="{active: active === '/guide'}"
               href="/guide">이용 가이드</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               v-bind:class="{active: active === '/notices'}"
               href="/notices">공지사항</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               v-bind:class="{active: active === '/couples'}"
               href="/couples">명예의 전당</a>
          </li>
          <li class="nav-item" v-if="isLoggedIn">
            <a class="nav-link"
               href="/users/deregistration_confirm">회원 탈퇴
            </a>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
               aria-expanded="false">
              다운로드
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item"
                     @click="clickDownloadBtnAndroid"
                     href="https://play.google.com/store/apps/details?id=me.withcoffee.android&hl=ko&utm_source=coffee-webutm_medium=website&utm_campaign=v3">안드로이드</a>
              </li>
              <li><a class="dropdown-item"
                     @click="clickDownloadBtnIos"
                     href="https://apps.apple.com/kr/app/id1306780305?ct=coffee-web">아이폰</a></li>
            </ul>
          </li>

          <li class="nav-item ms-lg-auto" v-if="isLoggedIn">
            <a class="nav-link logout" href="/logout" @click.prevent="logout">로그아웃</a>
          </li>
          <li class="nav-item ms-lg-auto" v-else>
            <a class="nav-link login" href="/login">로그인</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import logo from '../../images/noti_profile_01.png';

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false,
    }
  },
  components: {},
  data() {
    return {
      active: null,
      logoSrc: logo,
    }
  },
  beforeMount() {
  },
  methods: {
    logout() {
      fetch('/logout', {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        credentials: 'same-origin', // Necessary for including cookies and CSRF token
      })
        .then(response => {
          if (response.ok) {
            // Redirect or perform other actions upon successful logout
            window.location.href = '/'; // Adjust the redirection URL as needed
          } else {
            // Handle errors or unsuccessful logout attempts
            console.error('Logout failed');
          }
        })
        .catch(error => console.error('Error:', error));
    },
    clickDownloadBtnIos(event) {
      if (typeof gtag !== 'undefined') {
        gtag('event', 'app_download_click_from_web', {
          'event_label': 'ios',
          'event_category': 'behavior_event',
          'source': 'gnb_ios',
        });
      }
    },
    clickDownloadBtnAndroid(event) {
      if (typeof gtag !== 'undefined') {
        gtag('event', 'app_download_click_from_web', {
          'event_label': 'android',
          'event_category': 'behavior_event',
          'source': 'gnb_android',
        });
      }
    },
  },
  mounted() {
    this.active = window.location.pathname;
  },
}
</script>

<style lang="scss">
nav {
  background-color: #491c92;

  li {
    .login, .logout {
      color: white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

//#logout {
//  text-decoration: underline;
//  text-decoration-color: white;
//  color: black;
//}
//
//nav .container-fluid {
//  display: flex;
//  justify-content: space-between;
//}
//
//.navbar-nav {
//  display: flex;
//  align-items: center;
//}
//
///* Ensure the logout link aligns to the right */
//.ms-auto {
//  margin-left: auto;
//}
</style>