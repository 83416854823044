<template>
  <div id="guide">
    <global-nav-header :is-logged-in="isLoggedIn"/>
    <div class="content-wrapper">
      <h1>커피한잔 이용 가이드</h1>
      <div v-for="guide in guides">
        <guide-list-item :title="guide.title" :content="guide.content" />
      </div>
    </div>
    <coffee-footer/>
  </div>
</template>

<script>
import GlobalNavHeader from "./global_nav_header.vue";
import GuideListItem from "./guide_list_item.vue";
import CoffeeFooter from "./coffee_footer.vue";

export default {
  components: {GlobalNavHeader, GuideListItem, CoffeeFooter},
  methods: {},
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.guides = [
      {
        title: '채팅방은 종료 기한이 있나요?',
        content: `채팅방은 종료 기한이 없습니다.\n한 사람이 종료 할 때 까지 채팅방은 계속 유지됩니다.`,
      },
      {
        title: '카톡으로 넘어가서 대화해도 되나요?',
        content: `네, 괜찮습니다.
        카톡 아이디를 공개하기 싫으면 <a href="https://cs.kakao.com/helps?category=105&locale=ko&service=8">오픈 채팅</a>을 사용하시는 것도 추천드립니다.
        채팅방이 열리지 마자 카톡 아이디 부터 알려주는 것은 금지됩니다. 대화를 하다가 자연스레 교환해보세요.
        자기소개나 닉네임에 카톡 아이디를 공유하면 경고 없이 계정이 삭제되며 재가입 할 수 없습니다.

        <a href="https://withcoffee.app/notices/3">관련 공지사항 읽어보기</a>`,
      },
      {
        title: '커피한잔 거절은 어떻게 하나요?',
        content: '커피한잔에는 거절 기능을 만들지 않았어요. 명함이 사라질 때 까지 기다리시면 됩니다.',
      },
      {
        title: '명함은 정오에만 오나요?',
        content: `정오에 정기적으로 소개를 시켜드립니다만, 다른 사용자 분이 명함 한 장 더 받기를 하는 등 추가로 명함을 받을 기회들이 있습니다.
        더 궁금하신 분은 아래 공지사항을 읽어보세요.

        <a href="https://withcoffee.app/notices/28">명함이 들쑥날쑥 오는데 이유를 모르겠어요.</a>`
      },
      {
        title: '좋아하는 스타일대로 명함이 오지 않아요.',
        content: `선호 사항을 반영하여 소개를 시켜드리지만, 항상 반영되는 것은 아닙니다.
        더 자세한 내용은 아래 공지사항을 참고해주세요.

        <a href="https://withcoffee.app/notices/5">좋아하는 스타일은 어떻게 동작하나요?</a>`,
      },
      {
        title: '채팅은 동시에 오직 1명과만 할 수 있나요?',
        content: `아니요. 여러 명과 하게 되는 경우도 있습니다.
        커피한잔은 채팅 중에는 더 이상 소개를 시켜드리지 않지만 그게 꼭 동시에 1명과 채팅할 수 있다는 뜻은 아닙니다.

        명함을 여러 장 받은 경우 상대방과 서로 커피한잔 신청을 하면 1개 이상의 채팅방이 열릴 수도 있습니다.
        다른 채팅방이 열려도 기존 채팅방이 자동으로 닫히지는 않습니다.`,
      },
      {
        title: '정오인데 명함이 안왔어요.',
        content: `커피한잔은 하루에 매일 소개를 보장하지 않습니다.
        그렇게 하고 싶지만 할 수가 없습니다.
        자세한 내용은 아래 공지사항을 읽어보세요.

        <a href="https://withcoffee.app/notices/22">소개를 매일 못 해드려서 죄송합니다.😭</a>
        `,
      },
      {
        title: '매일 로그인 커피콩이 적립되지 않아요.',
        content: `로그인 커피콩은 사용자에 따라 가변적으로 적립이 됩니다.
        가입 기간, 무료 커피콩 누적 적립수, 최근 결제일, 재가입, 휴면이나 채팅 여부 등 여러 요소에 의해 다르게 반영이 되도록 만들었습니다.

        또한 커피콩을 사용하지 않고 계속 쌓아만 가는 경우 더 이상 로그인 커피콩이 적립되지 않습니다. 커피콩을 소비하면 다시 로그인 커피콩이 적립됩니다.

        무료 커피콩을 받는 많은 방법들이 있으니 참여해보세요!

        <a href="https://withcoffee.app/notices/32">스토어에 리뷰 남기고 커피콩 받기</a>
        <a href="https://withcoffee.app/notices/35">커뮤니티에 알리고 커피콩 받기</a>
        `,
      },
      {
        title: '상대가 접속조차 하지 않으면 커피콩 100%를 돌려받을 수 있나요?',
        content: `네, 커피한잔 신청을 한 이후 상대방이 접속조차 하지 않은 경우에는 커피한잔 신청했던 커피콩 전부를 돌려받게 됩니다.
        상대방이 접속을 했지만 수락하지 않았다면 50%를 돌려 받습니다.`
      },
      {
        title: '회사를 이직 했는데 어떻게 하면 되나요?',
        content: `withcoffee.me@gmail.com 으로 <b>'이직한 회사명'</b>과 <b>'회사 이메일 주소'</b> 그리고 <b>'이전 회사 메일 주소'</b>를 알려주세요.
        인증을 위해 이직하신 회사 이메일을 통해 메일을 보내주셔야 합니다.`,
      },
      {
        title: '회사 이름을 변경해줄 수 있나요?',
        content: `회사가 합병하거나 다른 이유로 이름이 변경된 경우 이메일로 저에게 알려주세요. 바로 반영해 드리겠습니다.`,
      },
      {
        title: '소개를 더 많이 받을 수 있는 팁이 있나요?',
        content: `저는 의지가 있는 사용자 분들에게 더 소개를 많이 시켜드리고 싶어요.
        1. 앱에 자주 접속하기
        2. 커피한잔 신청을 자주 하기
        3. 프로필과 자기소개를 정성껏 작성하기

        이런 케이스들은 만날 의지가 있다는 것에 공감하시죠?

        아래 공지사항도 한 번 읽어보세요.
        <a href="https://withcoffee.app/notices/2">커피한잔의 매칭 시스템</a>`,
      },
      {
        title: '선호 키를 설정할 수 없나요?',
        content: `커피한잔의 초기에는 선호 키를 설정할 수 있었어요.
        하지만 너무 많은 여성 사용자 분들께서 175, 180cm 이상을 만나고 싶다 설정하셨어요.
        이보다 작은 남성 사용자분들은 명함을 받을 기회 조차 갖지 못했거든요.
        외모를 가장 먼저 보지 말자는 커피한잔의 철학과 모순된다고 생각해서 이 기능을 삭제했어요.`,
      },
      {
        title: '선호 나이의 범위를 더 좁히고 싶어요.',
        content: `커피한잔은 아직 이용자가 충분하지 않기 때문에 선호 나이에 넓은 범위 제한을 뒀어요.
        이용자가 늘어감에 따라 여러분이 선호하는 나이를 더 잘 설정할 수 있도록 범위를 좁혀 볼게요!
        `,
      },
      {
        title: '지나간 명함은 다시 받을 수 없나요?',
        content: `채팅을 한 번 했던 상대는 다시 소개해드리지 않습니다.
        하지만 채팅으로 이어지지 않은 상대방은 시간이 많이 지나고 다면 다시 소개될 수 있습니다.
        이 기간은 사용자별로 다른데요, 어떤 경우든 꽤 오랜 시간을 기다려야 하기 때문에
        맘에 드는 명함을 보면 적극적으로 커피한잔 신청 해야 나중에 후회하지 않을꺼에요.`,
      },
      {
        title: '알림 설정은 어디에서 하나요?',
        content: `사용하시는 아이폰이나 안드로이드의 설정에 들어가시면 앱별로 알림 설정을 하는 곳이 있어요.
        혹시 알림 설정이 꺼져 있지 않은지 확인해보세요.`
      },
      {
        title: '상대방이 내 명함을 봤는지 알 수 있나요?',
        content: `커피한잔 신청을 하기 전에는 상대가 내 명함을 봤는지, 내가 상대의 명함을 봤는지를 서로에게 알려주지 않아요.
        다만 최근 접속일을 통해서 대강 유추해볼 수는 있겠죠.

        커피한잔 신청을 하고 난 후에는 좀 달라요.
        상대방이 커피한잔 신청을 확인했다면 즉시 알림을 통해 신청자에게 알려줍니다.`
      },
      {
        title: '회사 이메일로 아무 것도 보내지 말아주세요.',
        content: `naver.com 이나 hanmail.net 같은 보조 이메일을 등록할 수 있어요.
        보조 이메일이 설정되면 회사 이메일로 메일이 가지 않습니다.
        커피한잔 앱 내에서 설정할 수 있어요.`
      },
      {
        title: '탈퇴는 어떻게 하나요?',
        content: `탈퇴는 앱 설정 내의 탈퇴 메뉴에서 할 수 있어요.
        탈퇴 즉시 모든 개인 정보가 파기되며, 재가입은 1달 뒤에 가능합니다.
        앱을 삭제하신 분은 저에게 이메일을 보내주세요. withcoffee.me@gmail.com 으로 탈퇴 요청을 보내주셔도 됩니다.
        단, 본인의 계정임을 확인할 수 있는 정보를 함께 보내주세요. ex) 정확한 회사 이메일 주소와 앱 내 닉네임`
      },
      {
        title: '탈퇴 후 재가입 할 수 있나요?',
        content: `탈퇴 후에는 한 달 뒤에 재가입 할 수 있으니 신중하게 선택해주세요.
        재가입 시에는 무료 커피콩 관련 혜택이 줄어듭니다.`
      },
      {
        title: '명함의 색상은 어떤 의미가 있나요?',
        content: `각 회사의 로고 색상을 반영했어요. 카카오는 노란색. 네이버는 초록색. 삼성전자는 파란색.
        작은 회사들의 경우는 랜덤으로 색상을 갖게됩니다.`
      },
      {
        title: '커피콩이 소멸될 수 있나요?',
        content: `네, 오랫동안 사용하지 않은 무료 커피콩은 소멸될 수 있습니다.
        커피한잔 신청을 하면서 커피콩을 소비하고 또 새로운 무료 커피콩을 받으면 소멸되는 것을 방지할 수 있어요.
        유료로 구매한 커피콩은 소멸되지 않습니다.`
      },
      {
        title: '닉네임 변경을 할 수 있나요?',
        content: `네, 설정(4번째 탭) -> 계정 설정하기 -> 닉네임 변경하기를 찾아보세요.`
      },
      {
        title: '회사 메일이 없으면 가입할 수 없나요? 재직증명서나 명함으로 인증하고 싶어요.',
        content: `커피한잔은 오직 회사 메일로만 인증이 가능합니다. 메일이 수신 혹은 발신 중 하나가 가능함을 인증 해야만 합니다. 그 외의 다른 인증 방식은 지원하지 않습니다.`
      },
      {
        title: '비밀번호를 초기화 하고 싶어요.',
        content: `앱 내의 로그인 화면에 비밀번호 초기화 버튼이 있어요. 이메일 주소를 입력하면 초기화 링크가 이메일로 발송됩니다.
        만약 이메일을 받을 수 없다면 회사 메일로 저에게 이메일을 보내주세요. 메일 제목은 '비밀번호 초기화 요청' 이라고 보내주세요.
        반드시 '회사 메일로' 보내주셔야 합니다.`
      },
    ]
  },
  data() {
    return {
      guides: []
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../stylesheets/common";

#guide {
  .content-wrapper {
    padding: 0;
    max-width: $desktop-max-width;
    margin: 20px auto;
    text-align: left;
    word-break: keep-all;
    font-size: 16px;

    h1 {
      font-size: 25px;
      text-align: center;
      margin: 25px;
    }
  }
}
</style>
